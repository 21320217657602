@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-shadow-glow {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.animate-shake {
  animation: shake 0.5s ease-in-out;
}

.animate-success {
  animation: success-bounce 1s ease-in-out infinite;
}

.shadow-glow-white {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.shadow-glow-blue {
  box-shadow: 0 0 15px rgba(59, 130, 246, 0.5);
}

.animate-float {
  animation: float 20s ease-in-out infinite;
}

.perspective-\[1000px\] {
  perspective: 1000px;
}

.transform-style-preserve-3d {
  transform-style: preserve-3d;
}

.perspective-\[1500px\] {
  perspective: 1500px;
}

.animate-glow {
  animation: glow 2s ease-in-out infinite;
}

.perspective-1000 {
  perspective: 1000px;
}
.backface-hidden {
  backface-visibility: hidden;
}
.transform-style-preserve-3d {
  transform-style: preserve-3d;
}
.rotate-y-180 {
  transform: rotateY(180deg);
}

.animate-pulse-subtle {
  animation: pulse-subtle 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-shine {
  animation: shine 3s infinite;
}

/* Animations */

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes pulse-subtle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes glow {
  0%, 100% { opacity: 0.7; }
  50% { opacity: 0.9; }
}

@keyframes pulse {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes pulse-glow {
  0% {
    opacity: 0.4;
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    opacity: 0.6;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 0.4;
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

@keyframes slide {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes float {
  0%, 100% { transform: perspective(1000px) rotateX(5deg) translateY(0px); }
  50% { transform: perspective(1000px) rotateX(5deg) translateY(-10px); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  60% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
}

@keyframes success-bounce {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

@keyframes floatDown {
  from {
    background-position: right 10% top -600px;
  }
  to {
    background-position: right 10% top 0;
  }
}

@keyframes floatBooks {
  0% {
    background-position: right 10% top -600px;
  }
  100% {
    background-position: right 10% top 0;
  }
}

@keyframes float {
  0%, 100% { 
    transform: translateY(0) translateX(0);
  }
  50% { 
    transform: translateY(-5%) translateX(2%);
  }
}

/* Updated Gilmer Font declarations */
@font-face {
  font-family: 'Gilmer';
  src: url('./fonts/Gilmer-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilmer';
  src: url('./fonts/Gilmer-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilmer';
  src: url('./fonts/gilmer-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Layer base styles */
@layer base {
  html {
    @apply antialiased;
  }
  
  body {
    @apply text-primary-navy font-gilmer;
  }
}

/* Layer component styles */
@layer components {
  .App {
    @apply text-center;
  }

  .App-logo {
    @apply h-[40vmin] pointer-events-none;
  }

  .App-header {
    @apply bg-[#282c34] min-h-screen flex flex-col items-center justify-center text-[calc(10px_+_2vmin)] text-white;
  }

  .App-link {
    @apply text-[#61dafb];
  }
}